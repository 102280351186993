import { FC, PropsWithChildren, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import Modal from 'src/components/Modal';
import OneTrustLoader from 'src/components/OneTrustLoader';
import SeoKeywords from 'src/components/SeoKeywords';
import Toast from 'src/components/Toast';
import useCustomWindowMessageEvent from 'src/hooks/useCustomWindowMessageEvent';
import { useGetConsents } from 'src/hooks/useGetConsents';
import useGetMyProfile from 'src/hooks/useGetMyProfile';
import useGetVisitorConfigs from 'src/hooks/useGetVisitorConfigs';
import useInduceRegisterModal from 'src/hooks/useInduceRegisterModal';
import useLiveOffModal from 'src/hooks/useLiveOffModal';
import useLogin from 'src/hooks/useLogin';
import useLoginUserOrderedModal from 'src/hooks/useLoginUserOrderedModal';
import { useOnline } from 'src/hooks/useOnline';
import useScreenOpen from 'src/hooks/useScreenOpen';
import useSelectedTab from 'src/hooks/useSelectedTab';
import useSession from 'src/hooks/useSession';
import useStomp from 'src/hooks/useStomp';
import useUpdateInventory from 'src/hooks/useUpdateInventory';
import useUpdateUserInfo from 'src/hooks/useUpdateUserInfo';
import useWebpAndAvifSupport from 'src/hooks/useWebpAndAvifSupport';
import useWebviewFont from 'src/hooks/useWebviewFont';
import { setDTIDAtom, removeTokenAtom, userDataAtom } from 'src/stores/auth/atoms';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { signUpSettingsLoadableAtom } from 'src/stores/register/atoms';
import { visitorBirthAtom } from 'src/stores/termsAge/atoms';
import { PageMetas } from 'src/types/meta';
import { Container, RootStyle } from './style';
type Props = PageMetas;
const Layout: FC<PropsWithChildren<Props>> = ({
  children,
  metas
}) => {
  const router = useRouter();
  const closeAllModal = useSetAtom(closeAllModalAtom);
  const {
    isLogin
  } = useLogin();
  const {
    serverSideTabName,
    reportSelectedTab
  } = useSelectedTab();
  useWebpAndAvifSupport();
  useSession();
  useUpdateUserInfo();
  const {
    isStompReady
  } = useStomp();
  useScreenOpen();
  useLoginUserOrderedModal();
  useEffect(() => {
    const handleRouteChange = () => {
      closeAllModal();
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [closeAllModal, router.events]);
  useWebviewFont();
  useGetVisitorConfigs();
  useGetMyProfile();
  useLiveOffModal();
  useUpdateInventory();
  // 회원가입 퍼널 UX에서 로딩을 피하기 위해, 생년월일 A/B 테스트 설정을 미리 로드
  useAtomValue(signUpSettingsLoadableAtom);
  // 회원가입 퍼널 UX에서 로딩을 피하기 위해, 약관 A/B 테스트 설정을 미리 로드
  useGetConsents();
  useInduceRegisterModal();
  useCustomWindowMessageEvent();
  useOnline();
  const userData = useAtomValue(userDataAtom);
  const logout = useSetAtom(removeTokenAtom);
  const setVisitorBirth = useSetAtom(visitorBirthAtom);
  useEffect(() => {
    // user-info api 필드 커뮤니케이션 오류로 userProfile이 없는 유저가 있고, userProfile.xx로 접근하면 크래시 나서 로그아웃 처리
    if (userData?.userId && !userData?.userProfile) {
      logout();
    }
  }, [logout, userData?.userId, userData?.userProfile]);
  const setDTID = useSetAtom(setDTIDAtom);
  useEffect(() => {
    setDTID();
  }, [setDTID]);
  useEffect(() => {
    Sentry.setUser({
      id: userData?.userId
    });
  }, [userData?.userId]);
  useEffect(() => {
    if (isLogin) {
      setVisitorBirth(null);
    }
  }, [isLogin, setVisitorBirth]);
  useEffect(() => {
    /**
     * 앱과 웹의 탭 종류 불일치로 현재 select-tab API에서 처리할 수 있는 탭의 종류가 제한됨 (앱의 API를 그대로 활용)
     * 추가적으로 select-tab에 대한 수요가 현재 미러탭밖에 없기 때문에 미러에 한해서 API 호출
     * 추후 다른 탭에 대한 수요가 생기면 API 호출 범위를 확장
     */
    if (serverSideTabName === 'MIRROR' && isLogin && isStompReady) {
      reportSelectedTab();
    }
  }, [serverSideTabName, reportSelectedTab, isLogin, isStompReady]);
  return <OneTrustLoader data-sentry-element="OneTrustLoader" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
      <RootStyle data-sentry-element="RootStyle" data-sentry-source-file="index.tsx">
        <SeoKeywords metas={metas} data-sentry-element="SeoKeywords" data-sentry-source-file="index.tsx" />
        <>
          <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">{children}</Container>
          <Toast data-sentry-element="Toast" data-sentry-source-file="index.tsx" />
          <Modal data-sentry-element="Modal" data-sentry-source-file="index.tsx" />
        </>
      </RootStyle>
    </OneTrustLoader>;
};
export default Layout;